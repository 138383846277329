import React from 'react'
import styled from 'styled-components'

export type HamburgerProps = {
    asX?: boolean
} & React.HTMLAttributes<HTMLDivElement>

const Animated = styled.div`
    font-size: inherit;
    width: 1em;
    height: 1em;
    flex: 0 0 1em;
    position: relative;
    > * {
        transition: all 300ms ease-in-out;
    }
`

const Line = styled.div`
    position: absolute;
    height: 2px;
    background-color: black;
    border-radius: 14px;
`

export const Hamburger: React.FC<HamburgerProps> = React.memo(({ asX, ...props }) => {
    return (
        <Animated {...props}>
            <Line
                style={{
                    top: '50%',
                    width: '100%',
                    opacity: asX ? 0 : 1,
                }}
            />
            <Line
                style={{
                    top: asX ? '50%' : '25%',
                    width: '100%',
                    transform: asX ? 'rotate(45deg)' : 'rotate(0)',
                    transformOrigin: 'center center',
                }}
            />
            <Line
                style={{
                    top: asX ? '50%' : '75%',
                    width: '100%',
                    transform: asX ? 'rotate(-45deg)' : 'rotate(0)',
                    transformOrigin: 'center center',
                }}
            />
        </Animated>
    )
})

Hamburger.displayName = 'Hamburger'

export default Hamburger
