import React from 'react'

export type FacebookProps = {}

export const Facebook: React.FC<FacebookProps> = React.memo(({}) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='32px'
            height='32px'
            viewBox='0 0 32 32'
            version='1.1'
        >
            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g
                    transform='translate(-1080.000000, -360.000000)'
                    fill='#1C211F'
                    fillRule='nonzero'
                >
                    <g transform='translate(1080.000000, 360.000000)'>
                        <path d='M32.0000002,16 C31.9986598,7.48880277 25.334311,0.468327352 16.8347036,0.0243070395 C8.33509615,-0.419713273 0.975518364,5.86815087 0.0873541189,14.3328803 C-0.800810126,22.7976097 5.09334113,30.4761253 13.5,31.806 L13.5,20.625 L9.438,20.625 L9.438,16 L13.5,16 L13.5,12.475 C13.5,8.465 15.889,6.25 19.543,6.25 C20.7430042,6.26664271 21.9401897,6.37092018 23.125,6.562 L23.125,10.5 L21.107,10.5 C20.4104179,10.4074861 19.7095683,10.6367926 19.202386,11.1231583 C18.6952038,11.6095241 18.4367436,12.3001542 18.5,13 L18.5,16 L22.938,16 L22.228,20.625 L18.5,20.625 L18.5,31.806 C26.275676,30.5759426 32.0012396,23.8723679 32.0000002,16 Z' />
                    </g>
                </g>
            </g>
        </svg>
    )
})

Facebook.displayName = 'Facebook'

export default Facebook
