import React from 'react'

export type LogoProps = {} & React.HTMLAttributes<SVGElement>

export const Logo: React.FC<LogoProps> = React.memo((props) => {
    return (
        <svg
            width='143'
            height='36'
            viewBox='0 0 143 36'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d='M17.388 7.868C15.792 6.888 13.916 6.44 12.068 6.44C5.852 6.44 0.84 11.312 0.84 17.556C0.84 23.66 5.824 28.448 11.844 28.448C13.692 28.448 15.764 27.916 17.388 26.964V23.856C15.82 25.256 13.832 26.04 11.76 26.04C7.056 26.04 3.472 22.092 3.472 17.472C3.472 12.796 7.084 8.848 11.816 8.848C13.804 8.848 15.876 9.576 17.388 10.92V7.868ZM30.1037 6.44C24.0557 6.44 19.0437 11.396 19.0437 17.444C19.0437 23.716 23.9997 28.448 30.1037 28.448C36.2077 28.448 41.1637 23.716 41.1637 17.444C41.1637 11.396 36.1517 6.44 30.1037 6.44ZM30.1037 25.984C25.5397 25.984 21.6757 22.26 21.6757 17.444C21.6757 12.796 25.1477 8.904 30.1037 8.904C35.0597 8.904 38.5317 12.796 38.5317 17.444C38.5317 22.26 34.6677 25.984 30.1037 25.984ZM43.4632 28H46.0952V12.264L61.9432 28.868V6.888H59.3112V22.484L43.4632 5.88V28Z'
                fill='#2E2E2E'
            />
            <path
                d='M94.928 29.448L101.256 15.056H101.312L103.776 29H106.492L102.18 6.992L94.928 23.624L87.676 6.992L83.364 29H86.08L88.544 15.056H88.6L94.928 29.448ZM108.832 29H120.172V26.592H111.464V18.612H119.92V16.204H111.464V10.296H120.172V7.888H108.832V29ZM129.902 19.816C132.954 19.452 134.97 16.932 134.97 13.908C134.97 11.892 133.962 9.904 132.198 8.868C130.518 7.916 128.502 7.888 126.626 7.888H123.434V29H126.066V20.068H127.158L133.374 29H136.566L129.902 19.816ZM126.066 10.24H126.766C129.37 10.24 132.506 10.716 132.506 14.02C132.506 17.38 129.594 17.884 126.906 17.884H126.066V10.24Z'
                fill='#2E2E2E'
            />
            <path
                d='M79.312 29.112H82.196L73.012 6.88L63.856 29.112L66.74 29.112L73.012 13.488L79.312 29.112Z'
                fill='#666666'
            />
        </svg>
    )
})

Logo.displayName = 'Logo'

export default Logo
