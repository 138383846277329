import React from 'react'

export type PinterestProps = {}

export const Pinterest: React.FC<PinterestProps> = React.memo(({}) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='32px'
            height='32px'
            viewBox='0 0 32 32'
            version='1.1'
        >
            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g
                    transform='translate(-1168.000000, -360.000000)'
                    fill='#1C211F'
                    fillRule='nonzero'
                >
                    <g transform='translate(1168.000000, 360.000000)'>
                        <path
                            d='M16,0 C7.2,0 0,7.2 0,16 C0,22.8 4.2,28.6 10.2,30.9 C10.1,29.6 9.9,27.7 10.3,26.3 C10.6,25.1 12.2,18.3 12.2,18.3 C12.2,18.3 11.7,17.3 11.7,15.9 C11.7,13.7 13,12 14.6,12 C16,12 16.6,13 16.6,14.3 C16.6,15.7 15.7,17.7 15.3,19.6 C14.9,21.2 16.1,22.5 17.7,22.5 C20.5,22.5 22.7,19.5 22.7,15.2 C22.7,11.4 19.9,8.7 16,8.7 C11.4,8.7 8.8,12.1 8.8,15.6 C8.8,17 9.3,18.4 10,19.3 C10.1,19.5 10.1,19.6 10.1,19.8 C10,20.3 9.7,21.4 9.7,21.6 C9.5,21.9 9.3,22 9,21.8 C7,20.9 5.8,17.9 5.8,15.6 C5.8,10.6 9.5,5.9 16.4,5.9 C22,5.9 26.3,9.9 26.3,15.1 C26.3,20.6 22.8,25.1 18,25.1 C16.4,25.1 14.9,24.3 14.3,23.3 C14.3,23.3 13.5,26.4 13.3,27.1 C12.9,28.5 12,30.2 11.3,31.3 C12.8,31.8 14.4,32 16,32 C24.8,32 32,24.8 32,16 C32,7.2 24.8,0 16,0 Z'
                            id='Path'
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
})

Pinterest.displayName = 'Pinterest'

export default Pinterest
