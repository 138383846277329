import React from 'react'
import styled from 'styled-components'
import StarSvg from '../../images/star.svg'

const colors = {
    disabled: '#383838',
    active: '#FAB32A',
}

const Wrapper = styled.div`
    position: relative;
    height: 0.91304347em;
    flex-shrink: 0;
`

const Partial = styled.div`
    overflow: hidden;
`

export type StarProps = {
    rate: number
    size?: number
}

export const Star: React.FC<StarProps> = React.memo(({ rate, size = 23 }) => {
    return (
        <Wrapper
            style={{
                width: '1em',
                fontSize: size,
            }}
        >
            <Partial
                style={{
                    width: `${rate * 100}%`,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1,
                }}
            >
                <StarSvg
                    style={{
                        color: colors.active,
                        width: '1em',
                        height: '1em',
                    }}
                />
            </Partial>

            {rate !== 1 && (
                <Partial
                    style={{
                        right: 0,
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: `${rate * 100}%`,
                        overflow: 'hidden',
                        zIndex: 0,
                    }}
                >
                    <StarSvg
                        style={{
                            color: colors.disabled,
                            position: 'absolute',
                            left: `${-(rate / (1 - rate)) * 100}%`,
                            width: '1em',
                            height: '1em',
                        }}
                    />
                </Partial>
            )}
        </Wrapper>
    )
})

Star.displayName = 'Star'

export default Star
