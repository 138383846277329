import React from 'react'
import styled from 'styled-components'
import { FCol, FRow } from '../../atoms/Layout'
import { H3, P } from '../../atoms/Text'
import { range } from '../../utils'
import { sBreakpoints } from '../../constants'
import { useRenderBreakpoints } from '../../utils/hooks/render'

const Wrapper = styled.div`
    display: grid;
    place-items: stretch;
`

const Header = styled(H3)`
    font-size: 2rem;
    font-weight: 700;
    ${sBreakpoints.tablet} {
        font-size: 1rem;
    }
`

const FeatureNameCell = styled(FRow)`
    padding: 0 50px;
    height: 66px;
    position: relative;
    align-items: center;
`
const FeatureName = styled(P)`
    font-weight: bold;
    ${sBreakpoints.tablet} {
        font-size: 0.75rem;
    }
`
const FeatureValue = styled(P)`
    ${sBreakpoints.tablet} {
        font-size: 0.875rem;
        font-weight: 400;
    }
`

const Separator = styled.div`
    position: absolute;
    bottom: 0;
    right: 50px;
    left: 50px;
    height: 1px;
    background-color: rgba(28, 33, 31, 0.1);
    ${sBreakpoints.tablet} {
        left: 12px;
        right: 12px;
    }
`

const FeatureValueCell = styled(FRow)`
    padding: 0 50px;
    position: relative;
    align-items: center;

    ${sBreakpoints.tablet} {
        padding: 12px;
    }
`

const HeaderCell = styled(FRow)`
    flex: 1;
    align-items: flex-end;
    padding: 30px 50px 40px;
    z-index: 1;
    ${sBreakpoints.tablet} {
        padding: 30px 12px 12px;
    }
`

export type Header = {
    name: React.ReactNode
    features: React.ReactNode[]
}

export type ComparisonTableProps = {
    features: string[]
    values: Header[]
} & React.HTMLAttributes<HTMLDivElement>

export const ComparisonTable = React.memo<ComparisonTableProps>(
    ({ values: headers, features, ...rest }) => {
        const { onTablet } = useRenderBreakpoints()
        return onTablet(
            ({ matches: tablet }) => (
                <Wrapper
                    {...rest}
                    style={{
                        gridTemplateColumns: `repeat(${
                            headers.length + (tablet ? 0 : 1)
                        }, auto)`,
                        ...rest.style,
                    }}
                >
                    {!tablet && <div />}
                    {headers.map((h, idx) => (
                        <FRow key={idx}>
                            <HeaderCell
                                style={{
                                    marginTop: tablet
                                        ? 30
                                        : headers.length * 30 - (idx + 1) * 30,
                                    backgroundColor: `rgba(255,255,255, ${
                                        (idx + 1) / (headers.length + 1)
                                    })`,
                                }}
                            >
                                <Header>{h.name}</Header>
                            </HeaderCell>
                        </FRow>
                    ))}
                    {features.map((f, fIdx) => {
                        return [
                            !tablet && (
                                <FeatureNameCell key={'feature' + fIdx}>
                                    <FeatureName>{f}</FeatureName>
                                    {fIdx + 1 !== features.length && <Separator />}
                                </FeatureNameCell>
                            ),
                            ...headers.map((h, hIdx) => {
                                return (
                                    <FeatureValueCell
                                        key={fIdx * headers.length + hIdx}
                                        style={{
                                            backgroundColor: `rgba(255,255,255, ${
                                                (hIdx + 1) / (headers.length + 1)
                                            })`,
                                        }}
                                    >
                                        <FCol>
                                            {tablet && <FeatureName>{f}</FeatureName>}
                                            <FeatureValue>
                                                {h.features[fIdx]}
                                            </FeatureValue>
                                            {fIdx + 1 !== features.length && (
                                                <Separator />
                                            )}
                                        </FCol>
                                    </FeatureValueCell>
                                )
                            }),
                        ]
                    })}
                    {range(headers.length + (tablet ? 0 : 1)).map((x) => {
                        return (
                            <FRow
                                key={'extra' + x}
                                style={{
                                    backgroundColor: `rgba(255,255,255, ${
                                        (x + (tablet ? 1 : 0)) / (headers.length + 1)
                                    })`,
                                    minHeight: 50,
                                    marginBottom: tablet
                                        ? 15
                                        : headers.length * 15 - x * 15,
                                }}
                            />
                        )
                    })}
                </Wrapper>
            ),
            { mode: 'handle-both' },
        )
    },
)

ComparisonTable.displayName = 'ComparisonTable'

export default ComparisonTable
