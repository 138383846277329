import React from 'react'
import styled from 'styled-components'

export type DropdownArrowProps = {
    direction: 'up' | 'down'
} & React.HTMLAttributes<SVGElement>

const Svg = styled.svg<Pick<DropdownArrowProps, 'direction'>>`
    transition: all 0.3s ease-in-out;
    transform: rotate(${(props) => (props.direction === 'up' ? 0 : 180)}deg);
    color: #2e2e2e;
`

export const DropdownArrow: React.FC<DropdownArrowProps> = React.memo(
    ({ direction, ...rest }) => {
        return (
            <Svg
                direction={direction}
                width='17'
                height='9'
                viewBox='0 0 17 9'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                {...rest}
            >
                <path
                    d='M1 8L8.29593 1.61607C8.69213 1.26939 9.28927 1.28927 9.66154 1.66154L16 8'
                    stroke='currentColor'
                    strokeWidth='1.2'
                />
            </Svg>
        )
    },
)

DropdownArrow.displayName = 'DropdownArrow'

export default DropdownArrow
