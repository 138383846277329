import React from 'react'
import TreeSteps, { TreeStepsProps } from './index'
import { useTree } from './hook'
import { Button } from '../../atoms/Buttons/Button'
import { H3 } from '../../atoms/Text'
import { FCol, FRow } from '../../atoms/Layout'

type GenericData = {
    A: number
    B: number
    C: number
    D: number
}
export type TreeStepsSampleProps = {
    onPathChange?: TreeStepsProps<GenericData>['onPathChange']
}

const buildGenericStep = (name: string): React.FC => {
    // eslint-disable-next-line react/display-name
    return () => {
        const tree = useTree<GenericData>()
        return (
            <FCol>
                <div>Path length: {tree.path.length}</div>
                <div>Step name: {name}</div>
                <div>Data: {JSON.stringify(tree.data, null, 2)}</div>
                <H3>Change tree data</H3>
                <FRow>
                    {Object.keys(tree.data).map((key) => {
                        return (
                            <Button
                                key={key}
                                onClick={() => {
                                    tree.update((oldData) => ({
                                        [key]: oldData[key as keyof GenericData] + 1,
                                    }))
                                }}
                            >
                                Increment {key} count
                            </Button>
                        )
                    })}
                </FRow>
                <H3>Navigate</H3>
                <FRow>
                    {tree.path.length > 1 && (
                        <Button
                            onClick={() => {
                                tree.previous()
                            }}
                        >
                            Go back
                        </Button>
                    )}
                    {Object.keys(tree.current.children ?? {}).map((childName) => {
                        return (
                            <Button
                                key={'next-' + childName}
                                onClick={() => {
                                    tree.next(childName)
                                }}
                            >
                                Go to {childName}
                            </Button>
                        )
                    })}
                </FRow>
            </FCol>
        )
    }
}

const A = buildGenericStep('A')

const B = buildGenericStep('B')

const C = buildGenericStep('C')

const D = buildGenericStep('D')
export const sampleTree = {
    component: A,
    children: {
        B: {
            component: B,
        },
        C: {
            component: C,
            children: {
                D: { component: D },
            },
        },
    },
}
export const TreeStepsSample: React.FC<TreeStepsSampleProps> = React.memo(
    ({ onPathChange }) => {
        return (
            <React.Fragment>
                <TreeSteps<GenericData>
                    onPathChange={onPathChange}
                    tree={sampleTree}
                    initialData={{
                        A: 0,
                        B: 0,
                        C: 0,
                        D: 0,
                    }}
                />
            </React.Fragment>
        )
    },
)

TreeStepsSample.displayName = 'TreeStepsSample'

export default TreeStepsSample
