import React from 'react'
import { Span } from '../../atoms/Text'
import DropdownArrow from '../../atoms/Icons/DropdownArrow'
import { FRow } from '../../atoms/Layout'
import styled from 'styled-components'
import { columnGap } from '../../utils'
import { Collapse } from '@material-ui/core'

export type CollapsedProps = {
    title: string
    open?: boolean
    onClick(): void
}
export const Title = styled(Span)``

export const TitleWrapper = styled(FRow)`
    transition: opacity 0.2s ease-in-out;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
    ${columnGap(16)}
    cursor: pointer;
    opacity: 0.6;
    :hover {
        opacity: 1;
    }
`

export const Collapsed: React.FC<CollapsedProps> = React.memo(
    ({ title, open: _open, children, onClick }) => {
        const [open, setOpen] = React.useState(_open ?? false)
        const isOpen = _open ?? open
        return (
            <React.Fragment>
                <TitleWrapper
                    style={isOpen ? { opacity: 1 } : {}}
                    onClick={() => {
                        setOpen((p) => !p)
                        onClick?.()
                    }}
                >
                    <Title>{title}</Title>
                    <DropdownArrow direction={isOpen ? 'down' : 'up'} />
                </TitleWrapper>
                <Collapse in={isOpen} timeout={400}>
                    {children}
                </Collapse>
            </React.Fragment>
        )
    },
)

Collapsed.displayName = 'Collapsed'

export default Collapsed
