import React from 'react'

export type LinkedinProps = {}

export const Linkedin: React.FC<LinkedinProps> = React.memo(({}) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='32px'
            height='32px'
            viewBox='0 0 32 32'
            version='1.1'
        >
            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g transform='translate(-1124.000000, -360.000000)'>
                    <g transform='translate(1124.000000, 360.000000)'>
                        <circle fill='#1C211F' cx='16' cy='16' r='16' />
                        <g
                            transform='translate(8.000000, 8.000000)'
                            fill='#FFFFFF'
                            fillRule='nonzero'
                        >
                            <polygon points='3.55418182 15.7047273 0.272 15.7047273 0.272 5.27272727 3.55418182 5.27272727' />
                            <path d='M1.91272727,3.84073851 C0.859128826,3.83751629 0.00731945456,2.98137056 0.00945446008,1.92776939 C0.0115975051,0.874168226 0.866876142,0.0214882327 1.92047894,0.022554299 C2.97408174,0.0236223662 3.82763651,0.878033024 3.82763651,1.93163636 C3.82783271,2.439028 3.62596061,2.92560949 3.26663505,3.28384329 C2.90730949,3.64207709 2.42011597,3.8424676 1.91272727,3.84073851 L1.91272727,3.84073851 Z' />
                            <path d='M15.7243636,15.7047273 L12.5090909,15.7047273 L12.5090909,10.6589091 C12.5090909,9.432 12.5090909,7.93163636 10.8,7.93163636 C9.09090909,7.93163636 8.88509091,9.22763636 8.88509091,10.5905455 L8.88509091,15.7730909 L5.67272727,15.7730909 L5.67272727,5.27272727 L8.68072727,5.27272727 L8.68072727,6.70472727 L8.74909091,6.70472727 C9.38123422,5.61180188 10.5643637,4.95634088 11.8261818,5 C15.1083636,5 15.7236364,7.18181818 15.7236364,9.97745455 L15.7243636,15.7047273 Z' />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
})

Linkedin.displayName = 'Linkedin'

export default Linkedin
