import React from 'react'
import { FRowG8 } from '../Layout'
import Star from '../Star'
import { range } from '../../utils'

export type StarsProps = {
    rate: number
    max?: number
    size?: number
    layout?: React.FC
}

export const Stars: React.FC<StarsProps> = React.memo(
    ({ rate, max = 5, size, layout: Layout = FRowG8 }) => {
        return (
            <Layout>
                {range(max).map((index) => (
                    <Star
                        key={index}
                        rate={Math.max(Math.min(1, rate - index), 0)}
                        size={size}
                    />
                ))}
            </Layout>
        )
    },
)

Stars.displayName = 'Stars'

export default Stars
