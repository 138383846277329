import React from 'react'

export type InstagramProps = {}

export const Instagram: React.FC<InstagramProps> = React.memo(({}) => {
    return (
        <svg
            onClick={() => {
                window.open(
                    'https://www.instagram.com/construccionesconamer/',
                    '_blank',
                    'noopener',
                )
            }}
            style={{ cursor: 'pointer' }}
            xmlns='http://www.w3.org/2000/svg'
            width='32px'
            height='32px'
            viewBox='0 0 32 32'
            version='1.1'
        >
            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g transform='translate(-1036.000000, -360.000000)'>
                    <g transform='translate(1036.000000, 360.000000)'>
                        <circle fill='#1C211F' cx='16' cy='16' r='16' />
                        <g transform='translate(7.000000, 7.000000)' fillRule='nonzero'>
                            <path
                                d='M9,0 C6.5562,0 6.2496,0.0102 5.289,0.054 C3.88748567,0.0288649679 2.53141669,0.551274399 1.509,1.5102 C0.549910169,2.53251359 0.0274740174,3.88865193 0.0528,5.2902 C0.0102,6.2496 0,6.5562 0,9 C0,11.4438 0.0102,11.7504 0.054,12.711 C0.0288649679,14.1125143 0.551274399,15.4685833 1.5102,16.491 C2.53251359,17.4500898 3.88865193,17.972526 5.2902,17.9472 C6.2502,17.991 6.5574,18.0012 9.0012,18.0012 C11.445,18.0012 11.7516,17.991 12.7122,17.9472 C14.1137143,17.972335 15.4697833,17.4499256 16.4922,16.491 C17.4512898,15.4686864 17.973726,14.1125481 17.9484,12.711 C17.9922,11.751 18.0024,11.4438 18.0024,9 C18.0024,6.5562 17.9922,6.2496 17.9484,5.289 C17.973535,3.88748567 17.4511256,2.53141669 16.4922,1.509 C15.4698864,0.549910169 14.1137481,0.0274740174 12.7122,0.0528 C11.7504,0.0102 11.4438,0 9,0 Z'
                                fill='#FFFFFF'
                            />
                            <path
                                d='M9,4.38 C6.44844446,4.38 4.38,6.44844446 4.38,9 C4.38,11.5515555 6.44844446,13.62 9,13.62 C11.5515555,13.62 13.62,11.5515555 13.62,9 C13.62,6.44844446 11.5515555,4.38 9,4.38 Z M9,12 C7.34314575,12 6,10.6568542 6,9 C6,7.34314575 7.34314575,6 9,6 C10.6568542,6 12,7.34314575 12,9 C12,9.79564947 11.6839295,10.5587112 11.1213203,11.1213203 C10.5587112,11.6839295 9.79564947,12 9,12 Z'
                                fill='#1C211F'
                            />
                            <circle fill='#1C211F' cx='13.8042' cy='4.1958' r='1.08' />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
})

Instagram.displayName = 'Instagram'

export default Instagram
