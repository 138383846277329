import React from 'react'

export type TwitterProps = {}

export const Twitter: React.FC<TwitterProps> = React.memo(({}) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='32px'
            height='32px'
            viewBox='0 0 32 32'
            version='1.1'
        >
            <defs>
                <filter id='twitter-svg-filter'>
                    <feColorMatrix
                        in='SourceGraphic'
                        type='matrix'
                        values='0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0'
                    />
                </filter>
            </defs>
            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g transform='translate(-1212.000000, -360.000000)'>
                    <g transform='translate(1212.000000, 360.000000)'>
                        <circle fill='#1C211F' cx='16' cy='16' r='16' />
                        <g filter='url(#twitter-svg-filter)'>
                            <g transform='translate(6.000000, 8.000000)'>
                                <path
                                    d='M20,1.87878788 C19.2727273,2.18181818 18.5454545,2.42424242 17.6969697,2.48484848 C18.5454545,2 19.1515152,1.21212121 19.4545455,0.303030303 C18.6666667,0.787878788 17.8181818,1.09090909 16.9090909,1.27272727 C16.1818182,0.484848485 15.1515152,0 14.0606061,0 C11.8787879,0 10.0606061,1.75757576 10.0606061,4 C10.0606061,4.3030303 10.1212121,4.60606061 10.1818182,4.90909091 C6.84848485,4.72727273 3.93939394,3.15151515 1.93939394,0.727272727 C1.57575758,1.33333333 1.39393939,2 1.39393939,2.72727273 C1.39393939,4.12121212 2.12121212,5.33333333 3.15151515,6.06060606 C2.48484848,6.06060606 1.87878788,5.87878788 1.33333333,5.57575758 C1.33333333,5.57575758 1.33333333,5.63636364 1.33333333,5.63636364 C1.33333333,7.57575758 2.72727273,9.15151515 4.54545455,9.51515152 C4.18181818,9.57575758 3.87878788,9.63636364 3.51515152,9.63636364 C3.27272727,9.63636364 3.03030303,9.63636364 2.78787879,9.57575758 C3.27272727,11.1515152 4.78787879,12.3030303 6.48484848,12.3636364 C5.15151515,13.4545455 3.39393939,14.0606061 1.51515152,14.0606061 C1.21212121,14.0606061 0.848484848,14.0606061 0.545454545,14 C2.36363636,15.0909091 4.48484848,15.7575758 6.72727273,15.7575758 C14.0606061,15.7575758 18.0606061,9.6969697 18.0606061,4.42424242 C18.0606061,4.24242424 18.0606061,4.06060606 18.0606061,3.93939394 C18.7878788,3.33333333 19.4545455,2.66666667 20,1.87878788 Z'
                                    fill='#444444'
                                    fillRule='nonzero'
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
})

Twitter.displayName = 'Twitter'

export default Twitter
