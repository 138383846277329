import { range } from '../../utils'

export const fakeItems = range(10)
    .map(() => [
        {
            image: 'https://made-strapi.s3.us-west-2.amazonaws.com/mobile_nw_artisan1_2x_c497a3b6ae.jpg',
        },
        {
            image: 'https://made-strapi.s3.us-west-2.amazonaws.com/mobile_nw_artisan2_2x_ed05feb165.jpg',
        },
    ])
    .flat(1)
    .map((x, idx) => ({ ...x, id: String(idx) }))
