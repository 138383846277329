import * as React from 'react'
import Collage from '../molecules/Collage'
import { fakeItems } from '../molecules/Collage/fixtures'
import { H2, H3, InfoSmall, P, PBig } from '../atoms/Text'
import { range } from '../utils'
import ChoicesContainer from '../molecules/ChoicesContainer'
import Input from '../atoms/Input'
import { LoadingBar } from '../atoms/LoadingBar'
import { Button, ButtonBlack, ButtonPalid, ButtonWArrow } from '../atoms/Buttons/Button'
import RoundedCheckmark from '../atoms/Icons/RoundedCheckmark'
import DoubleArrow from '../atoms/Icons/DoubleArrow'
import { FRow } from '../atoms/Layout'
import TreeStepsSample from '../molecules/TreeSteps/sample'
import Logo from '../atoms/Icons/Logo'
import Lock from '../atoms/Icons/Lock'
import Close from '../atoms/Icons/Close'
import ArrowBack from '../atoms/Icons/ArrowBack'
import Hamburger from '../atoms/Icons/Hamburger'
import Divider from '../atoms/Divider'
import DropdownArrow from '../atoms/Icons/DropdownArrow'
import ThinArrow from '../atoms/Icons/ThinArrow'
import Collapsed from '../molecules/Collapsed'
import InfoSection, { colors, icons } from '../atoms/InfoSection'
import Facebook from '../atoms/Icons/Facebook'
import Pinterest from '../atoms/Icons/Pinterest'
import Linkedin from '../atoms/Icons/Linkedin'
import Twitter from '../atoms/Icons/Twitter'
import Instagram from '../atoms/Icons/Instagram'
import ComparisonTable from '../molecules/ComparisonTable'
import Stepper from '../molecules/Stepper'
import Stars from '../atoms/Stars'

// markup
const ComponentsPage: React.FC = () => {
    const [toggle, setToggle] = React.useState(false)
    const handleToggle = () => {
        setToggle((p) => !p)
    }
    const [progress, setProgress] = React.useState(0)

    return (
        <main>
            <title>Title</title>
            <div>
                <LoadingBar progress={progress} onLoaderFinished={() => setProgress(0)} />
                <H2>Stars</H2>
                <Stars rate={4.5} />
                <H2>Logo</H2>
                <Logo />
                <H2>Buttons</H2>
                <Button
                    onClick={() => {
                        setProgress((p) => p + 20)
                    }}
                >
                    Increase bar
                </Button>
                <ButtonBlack>Get estimate</ButtonBlack>
                <ButtonPalid>Get estimate</ButtonPalid>
                <ButtonWArrow as={ButtonBlack}>
                    Get estimate
                    <ThinArrow />
                </ButtonWArrow>
                <ButtonWArrow as={ButtonPalid}>
                    Get estimate
                    <ThinArrow />
                </ButtonWArrow>
                <H2>Notes with icons</H2>
                <FRow style={{ gap: '16px', flexWrap: 'wrap' }}>
                    {Object.keys(icons).map((i, idx) => {
                        const colorsArr = Object.keys(colors)
                        return (
                            <InfoSection
                                key={idx}
                                color={colorsArr[idx % colorsArr.length] as any}
                                icon={i as any}
                            >
                                This is an info section with <b>{i}</b> icon
                            </InfoSection>
                        )
                    })}
                </FRow>
                <H2>Collapsed</H2>
                <Collapsed title={'This content can be collapsed'}>
                    <div>
                        <H3>Lorem ipsum</H3>
                        <H3>Lorem ipsum</H3>
                        <H3>Lorem ipsum</H3>
                        <H3>Lorem ipsum</H3>
                        <H3>Lorem ipsum</H3>
                        <H3>Lorem ipsum</H3>
                    </div>
                </Collapsed>
                <H2>Icons</H2>
                <FRow style={{ flexWrap: 'wrap' }}>
                    <RoundedCheckmark />
                    <DoubleArrow direction={'up'} />
                    <DoubleArrow direction={'right'} />
                    <DoubleArrow direction={'down'} />
                    <DoubleArrow direction={'left'} />
                    <Close />
                    <ArrowBack />
                    <Lock />
                    <Hamburger />
                    <DropdownArrow direction={'up'} />
                    <DropdownArrow direction={'down'} />
                    <ThinArrow />
                    <Facebook />
                    <Pinterest />
                    <Linkedin />
                    <Twitter />
                    <Instagram />
                </FRow>
                <H2>Stepper</H2>
                <Stepper
                    steps={[
                        {
                            title: 'Select campaign settings',
                            description: `For each ad campaign that you create, you can control how much
              you're willing to spend on clicks and conversions, which networks
              and geographical locations you want your ads to show on, and more.`,
                            image: `https://picsum.photos/id/238/200/300`,
                        },
                        {
                            title: 'Create an ad group',
                            description:
                                'An ad group contains one or more ads which target a shared set of keywords.',
                            image: `https://picsum.photos/id/239/200/300`,
                        },
                        {
                            title: 'Create an ad',
                            description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
                            image: `https://picsum.photos/id/237/200/300`,
                        },
                    ]}
                />
                <H2>Comparison table</H2>
                <div style={{ backgroundColor: 'darkgreen' }}>
                    <ComparisonTable
                        features={['Renovation experience', 'Design']}
                        values={[
                            {
                                name: (
                                    <div>
                                        Typical
                                        <br />
                                        Experience
                                    </div>
                                ),
                                features: ['A la carte', 'Do it yourself'],
                            },
                            {
                                name: (
                                    <div>
                                        With
                                        <br />
                                        Conamer
                                    </div>
                                ),
                                features: ['All-inclusive', 'Experienced designer'],
                            },
                        ]}
                    />
                </div>
                <H2>Divider</H2>
                <Divider />
                <H2>Text; H2</H2>
                <H3>
                    H3: This helps us calculate labor and material costs. Most bathrooms
                    are “medium”.
                </H3>
                <PBig>This is PBig tag</PBig>
                <P>This is P tag</P>
                <InfoSmall>This is InfoSmall tag</InfoSmall>
                <H2>Choice with icon</H2>
                <ChoicesContainer
                    choices={range(5).map((x) => {
                        return {
                            id: String(x),
                            icon: (
                                <img
                                    src={
                                        'https://www.maderenovation.com/svg/estimator2-1-white.svg'
                                    }
                                    style={{ filter: 'contrast(0.1)' }}
                                    alt={'Small'}
                                    className={'white'}
                                />
                            ),
                            title: 'Small',
                            description: '<50 sg. ft.',
                        }
                    })}
                    selected={toggle ? range(5).map(String) : []}
                    onClick={handleToggle}
                />
                <H2>MultiChoice with icon</H2>
                <ChoicesContainer
                    multiChoice={true}
                    choices={range(5).map((x) => {
                        return {
                            id: String(x),
                            icon: (
                                <img
                                    src={
                                        'https://www.maderenovation.com/svg/estimator2-1-white.svg'
                                    }
                                    style={{ filter: 'contrast(0.1)' }}
                                    alt={'Small'}
                                    className={'white'}
                                />
                            ),
                            title: 'Small',
                            description: '<50 sg. ft.',
                        }
                    })}
                    selected={toggle ? range(5).map(String) : []}
                    onClick={handleToggle}
                />
                <H2>Multi Choice with text</H2>
                <ChoicesContainer
                    direction={'column'}
                    multiChoice={true}
                    selected={toggle ? range(2).map(String) : []}
                    onClick={handleToggle}
                    choices={[
                        { id: '0', title: 'Small' },
                        {
                            id: '1',
                            title: 'Small',
                            description: 'Some description here',
                        },
                    ]}
                />
                <H2>Single Choice with text</H2>
                <ChoicesContainer
                    direction={'column'}
                    selected={toggle ? ['1'] : ['2']}
                    onClick={handleToggle}
                    choices={[
                        { id: '1', title: 'Small' },
                        {
                            id: '2',
                            title: 'Small',
                            description: 'Some description here',
                        },
                    ]}
                />
                <H2>Inputs</H2>
                <Input placeholder={'First and Last Name'} />
                <Input placeholder={'Email'} />
                <Input placeholder={'Mobile Phone'} />

                <H2>Tree steps</H2>
                <TreeStepsSample />
                <H2>Collage</H2>
                <Collage items={fakeItems} nColumns={3} />
            </div>
        </main>
    )
}

export default ComponentsPage
