import React from 'react'
import styled from 'styled-components'

export type ThinArrowProps = {} & React.HTMLAttributes<HTMLElement>

const I = styled.i`
    color: currentColor;
    font-stretch: 100%;
    font-weight: 700;
    text-decoration-thickness: auto;
    text-size-adjust: 100%;
`

export const ThinArrow: React.FC<ThinArrowProps> = React.memo((props) => {
    return <I {...props}>→</I>
})

ThinArrow.displayName = 'ThinArrow'

export default ThinArrow
